import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel } from '../../../components';
import css from './SortByPopup.module.css';
import { FormattedMessage } from 'react-intl';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const SortByIcon = props => {
  const classes = classNames(css.icon, props.className);
  // extra small arrow head (down)
  return (
    <svg
      className={classes}
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.88672 6.1857L12.5877 0.492403C12.643 0.436769 12.709 0.392807 12.7816 0.363121C12.8542 0.333436 12.9321 0.31863 13.0106 0.319581C13.089 0.320532 13.1665 0.337221 13.2384 0.368659C13.3103 0.400096 13.3752 0.445644 13.4292 0.502603C13.5398 0.619108 13.6006 0.774147 13.5987 0.934773C13.5968 1.0954 13.5323 1.24895 13.419 1.3628L7.29642 7.47685C7.24148 7.53216 7.17607 7.57597 7.10401 7.6057C7.03195 7.63544 6.95468 7.65051 6.87673 7.65003C6.79877 7.64956 6.72169 7.63355 6.65 7.60294C6.5783 7.57233 6.51343 7.52773 6.45917 7.47175L0.17342 1.0364C0.0621747 0.921197 0 0.767303 0 0.607153C0 0.447003 0.0621747 0.293109 0.17342 0.177903C0.228086 0.121612 0.293491 0.0768618 0.365763 0.0463036C0.438035 0.0157454 0.515704 0 0.59417 0C0.672637 0 0.750306 0.0157454 0.822578 0.0463036C0.89485 0.0768618 0.960255 0.121612 1.01492 0.177903L6.88672 6.1857Z"
        fill="black"
      />
    </svg>
  );
};

class SortByPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(urlParam, option) {
    this.setState({ isOpen: false });
    this.props.onSelect(urlParam, option);
  }

  render() {
    const {
      rootClassName,
      className,
      menuLabelRootClassName,
      urlParam,
      label,
      options,
      initialValue,
      contentPlacementOffset,
    } = this.props;

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;

    const classes = classNames(rootClassName || css.root, className);
    const menuLabelClasses = classNames(menuLabelRootClassName || css.menuLabel);
    const iconArrowClassName = this.state.isOpen ? css.iconArrowAnimation : null;

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        contentPosition="left"
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel rootClassName={menuLabelClasses}>
          <FormattedMessage id="MainPanelHeader.sortBy" /> {menuLabel}
          <SortByIcon className={iconArrowClassName} />
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

            return (
              <MenuItem key={option.key}>
                <button
                  className={css.menuItem}
                  disabled={option.disabled}
                  onClick={() => (selected ? null : this.selectOption(urlParam, option.key))}
                >
                  <span className={menuItemBorderClass} />
                  {option.longLabel || option.label}
                </button>
              </MenuItem>
            );
          })}
        </MenuContent>
      </Menu>
    );
  }
}

SortByPopup.defaultProps = {
  rootClassName: null,
  className: null,
  menuLabelRootClassName: null,
  initialValue: null,
  contentPlacementOffset: 0,
};

SortByPopup.propTypes = {
  rootClassName: string,
  className: string,
  menuLabelRootClassName: string,
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
  contentPlacementOffset: number,
};

export default SortByPopup;
